import styles from '../special-dishes.module.css';

function SliderChiguire() {
    return <>
        <div className={styles['texto-slide-contenedor']}>
            <div className={styles['texto-slide']}>
                <p> CHIGUIRE ESTA A OTRO LEVEL -</p>    
            </div>
            <div className={styles['texto-slide']}>
                <p> CHIGUIRE ESTA A OTRO LEVEL -</p>   
            </div>
            <div className={styles['texto-slide']}>
                <p> CHIGUIRE ESTA A OTRO LEVEL -</p>   
            </div>
        </div>
    </>
}

export default SliderChiguire;